import { memo } from 'react'
import { default as MuiBox, type BoxProps } from '@mui/material/Box'
import { default as MuiTypograpahy, type TypographyProps } from '@mui/material/Typography'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export * from '@mui/material/index'

export const Box = memo(function Box({
  m,
  mt,
  mr,
  mb,
  ml,
  mx,
  my,
  p,
  pt,
  pr,
  pb,
  pl,
  px,
  py,
  width,
  height,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  display,
  flex,
  flexWrap,
  flexGrow,
  flexShrink,
  flexDirection,
  alignItems,
  justifyItems,
  alignContent,
  justifyContent,
  gap,
  color,
  textAlign,
  position,
  overflow,
  textOverflow,
  border,
  borderRadius,
  borderBottom,
  borderColor,
  bgcolor,
  gridArea,
  lineHeight,
  sx,
  ...props
}: BoxProps['sx'] & BoxProps) {
  return (
    <MuiBox
      sx={omitBy(
        {
          m,
          mt,
          mr,
          mb,
          ml,
          mx,
          my,
          p,
          pt,
          pr,
          pb,
          pl,
          px,
          py,
          width,
          height,
          minWidth,
          minHeight,
          maxWidth,
          maxHeight,
          display,
          flex,
          flexWrap,
          flexGrow,
          flexShrink,
          flexDirection,
          alignItems,
          justifyItems,
          alignContent,
          justifyContent,
          gap,
          color,
          textAlign,
          position,
          overflow,
          textOverflow,
          border,
          borderRadius,
          borderBottom,
          borderColor,
          bgcolor,
          gridArea,
          lineHeight,
          ...sx,
        },
        isUndefined,
      )}
      {...props}
    />
  )
})

export const Typography = memo(function Typography({
  m,
  mt,
  mr,
  mb,
  ml,
  mx,
  my,
  p,
  pt,
  pr,
  pb,
  pl,
  px,
  py,
  display,
  flex,
  flexWrap,
  flexGrow,
  flexShrink,
  flexDirection,
  alignItems,
  justifyItems,
  alignContent,
  justifyContent,
  gap,
  color,
  textAlign,
  fontSize,
  fontWeight,
  fontStyle,
  lineHeight,
  letterSpacing,
  whiteSpace,
  width,
  sx,
  ...props
}: TypographyProps['sx'] & TypographyProps) {
  return (
    <MuiTypograpahy
      sx={omitBy(
        {
          m,
          mt,
          mr,
          mb,
          ml,
          mx,
          my,
          p,
          pt,
          pr,
          pb,
          pl,
          px,
          py,
          display,
          flex,
          flexWrap,
          flexGrow,
          flexShrink,
          flexDirection,
          alignItems,
          justifyItems,
          alignContent,
          justifyContent,
          gap,
          color,
          textAlign,
          fontSize,
          fontWeight,
          fontStyle,
          lineHeight,
          letterSpacing,
          whiteSpace,
          width,
          ...sx,
        },
        isUndefined,
      )}
      {...props}
    />
  )
})
